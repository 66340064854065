.Wallet {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .profile-icon {
    display: block;
    width: 128px;

    svg {
      height: 128px;
      width: 128px;
    }
  }

  .user-id {
    display: block; 
    margin: 0 0 3rem 0;
    color: #ccc;  
    font-size: 24px;
    font-weight: 400;  
  }



  .dr-claim {
    text-align: center;

    span {
      display: inline-block;
      margin: 0 0.25rem;
    }

    .amount {
      width: 50px;
    }
  }
  .wallet__stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .wallet__cards {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    width: 100%;

    .card {
      display: flex;
      padding: 1.5rem;
      font-size: 14px;
      border: 1px solid white;

      h3 {
        font-size: 13px;
        font-weight: 300;
        text-transform: uppercase;
        display: inline-flex;
        flex: 1 1 50%;
      }
      var {
        display: inline-flex;
        justify-self: flex-end;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 500;
        flex: 1 1 50%;
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    background-color: white;
    color: black;
    padding: 1rem 2rem;
    border-radius: 2px;
    border: 0;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      background-color: black;
      color: white;
    }

    &.small {
      padding: 0.75rem 1.5rem;
      cursor: pointer;
    }
  }
}

.KeytoolCheck {
  .message {
    padding: 1rem;
    margin-bottom: 3rem;
    border: 1px solid white;
  }
  .w-link {
    display: flex;
    justify-content: center;
    align-items: center;

    a.button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 1rem auto;
      background-color: white;
      color: black;
      padding: 2rem;
      border-radius: 2px;
      text-decoration: none;
      font-weight: 500;
    }
  }
}

