.KeytoolLogout {

  .button {
    display: inline-block;
    padding: 1.25em 2rem;
    margin: 1.5rem 0;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    color: black;
    background-color: var(--color-most-bright-medium);
    border: 0;
    border-radius: 2px;

    &:hover {
      background-color: var(--color-most-bright-light);
      color: black;
      cursor: pointer;
    }
  }
}
