
.Edit {

  width: 100%;
  height: 220px;
  max-width: 740px;
  margin-bottom: 1rem;
  max-height: 220px;
  background-color: var(--color-light-blue);
  color: black;
  z-index: 10;

  .editor {
    width: 100%;
    height: 170px;
    display: block;
    padding: 1rem;
    background-color: transparent;
    border: 0;
    resize: none;
    line-height: 1.25;
    outline-width: 0;
    outline-style: dotted;
    outline-color: #333;  
  }

  .toolbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.2);

    .tag {
      display: inline-block;
      margin: 0 0.5rem 0 0;
      border-radius: 2px;
      padding: 3px 6px;
      background-color: var(--color-most-bright-medium);
      font-size: 13px;
      border: 1px solid var #ddd;
      line-height: 1.25;

      &:hover {
        background-color: var(--color-most-bright-light);
        color: black;
        cursor: pointer;
      }

    }


    input.reward {
      width: 30px;
      background-color: white;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 500;
      color: black;
      padding: 2px;
      line-height: 12px;
      text-align: center;
      border: 1px solid #ddd;
      margin-right: 0.5rem;
    }

    .note-tags {
      display: flex;
      padding: 0.5rem 1rem 0.5rem;

      .add-tag {
        width: 100px;
        display: flex;

        input {
          width: 80px;
          display: inline-flex;
          padding: 6px;
          font-size: 12px;
        }

        .inline-icon {
          align-self: center;
          margin-left: 0.5rem;
          display: inline-flex;
          width: 16px;

          svg {
            width: 16px;
            height: 16px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

    }
    
    .buttons {
      flex: 1 1 auto;
      width: 100px;
      padding: 4px 1rem 0 0.25rem;
      text-align: right;
      justify-self: flex-end;


      .btn  {
        padding-right: 0.25rem;

        &:hover {
          cursor: pointer;
        }

        svg {
          display: inline-block;
          flex: 24px;
          width: 24px;
          height: 24px;
          color: black;

          &:hover {
            color: white;
          }
    
        }

        &.add{
          padding-right: 0;
          justify-self: flex-end;

          svg {
            flex: 36px;
            width: 36px;
            height: 36px;
          }
        }
      }
      
    }  
  }

  .msg {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 14px;
  }

  .inline-icon {
    width: 24px;
    display: inline-flex;
    margin: 0 0.25rem 0;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}





