.SingleNote {
  display: grid;
  grid-template-columns: 24px auto 80px;
  column-gap: 0.5rem;
  align-items: center;
  height: 40px;
  padding: 0 1rem;
  font-size: 0.875rem;
  line-height: 40px;
  color: white;
  border-bottom: 1px solid #333;
  overflow: hidden;

  .content {
    height: 40px;
    overflow: hidden;
  }

 .btn-icon  {

  &.off {
    display: none;
  }

    svg {
      display: inline-block;
      width: 24px;
      height: 24px;
      color: white;

      &:hover {
        cursor: pointer;
        color: var(--color-bright-accent);
      }
    }
  }

  .actions {
    position: relative;
    width: 88px;
    display: flex;
    justify-content: flex-end;

    svg {
      margin-right: 0.5rem;
    }

  }

  .add-tag {
    position: absolute;
    top: -5px;
    right: 24px;
    width: 120px;
    white-space: nowrap;

    input {
      width: 80px;
      border-radius: 1px;
      border: 0;
      font-size: 12px;
    }

    .inline-icon {
      display: inline;
      padding-left: 0.5rem;
      
      svg {
        color: white;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .btn-icon.reward {
    position: relative;
    top: -5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    color: var(--color-most-bright-light);
    border-radius: 50%;
    border: 2px solid var(--color-most-bright-light);
    font-family: var(--font-fam-deco);

    &:hover {
      cursor: pointer;
      background-color: var(--color-most-bright-light);
      color: black;
    }
  }
 
}

