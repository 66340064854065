.KeytoolLogin {
   display: flex;
   width: 100%;
   flex-direction: column;
   align-items: center;

  h3 {
    font-size: 36px;
    font-weight: 900;
    text-align: center;
  }
  .hint {
    font-size: 14px;
    line-height: 1.618;
    margin: 0;
    font-weight: 400;
  }

  .wls-username-input {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ddd;
    border-radius: 1px;
    padding: 1rem 1rem 0.25rem 1rem;
    margin: 0.5rem 0 2rem;
    background-color: transparent;
    color: #ccc;
    text-align: center;

    &::placeholder {
      font-size: 0.875rem;
    }
  }

  .button {
    display: block;
    padding: 1.25em 2rem;
    margin: 1.5rem auto;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    color: black;
    background-color: var(--color-most-bright-medium);
    border: 0;
    border-radius: 2px;

    &:hover {
      background-color: var(--color-most-bright-light);
      color: black;
    }
  }
}

