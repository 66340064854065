.KeytoolCheck {
  .message {
    padding: 1rem;
    margin-bottom: 3rem;
    border: 1px solid white;
  }
  .w-link {
    display: flex;
    justify-content: center;
    align-items: center;

    a.button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 1rem auto;
      background-color: white;
      color: black;
      padding: 2rem;
      border-radius: 2px;
      text-decoration: none;
      font-weight: 500;
      width: 200px;

      &.chrome {
        background-color: black;
        color: white;
      }
      &.firefox {
        background-color: white;
        color: black;
      };
    }
  }
}
