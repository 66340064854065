.Main {
  width: 100%;
  max-width: 740px;
  display: grid;
  grid-template-rows: auto;
  gap: 0;
  margin: 0 auto;
  color: white;
  
  & > * {
    min-height: 0;
  }

  .filters {
    svg {
      position: relative;
      top: 0.25rem;
      display: inline-block;
      margin-left: 0.5rem;
      height: 16px;
      width: auto;

      &:hover {
        cursor: pointer;
      }
    }

    .tag {
      display: inline-block;
      margin-right: 0.25rem;
      border-radius: 2px;
      padding: 0.25rem 0.5rem;
      font-size: 14px;
      line-height: 1;
      background-color: white;
      color: black;
      border: var(--border-black);


      &::after {
        content: '\2A2F';
        color: white;
        cursor: pointer;
      }

      &:hover {
        background-color: black;
        color: white;
        border: var(--border-on-dark);
        cursor: pointer;
      }
    }
    .tag:hover::after {
      content: '\2A2F';
      color: white;
    }

  }

  .notelist {
    width: 100%;
    max-width: 740px; 
    display: grid;
    grid-template-rows: 40px;
    grid-auto-rows: 40px;
    gap: 10px;
    padding: 0.5rem 0;
    z-index: 8;
  }

}
