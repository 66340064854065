.TopNav {
  width: 100%;
  height: 60px;
  max-width: 740px;

  .topbar {
    display: grid;
    grid-template-columns: 40px  auto 40px 40px 40px;
    align-items: baseline;
    width: 100%;
    max-width: 740px;
    height: 52px;
    padding: 8px 1rem;
    z-index: 2;  
  }
  .topnav__icon {
    position: relative;
    top: 0.5rem;
    width: 36px;
    height: 36px; 
    margin-left: 0.25rem;
    z-index: 5;
    
    svg {
      width: 36px;
      height: 36px; 

      &:hover {
        cursor: pointer;
      }
    }
  }

  .logo {

    svg {
      position: relative;
      top: 5px;
      height: 30px; 
      color: var(--color-white);

      &:hover {
        cursor: pointer;
      }
    }
  }

  .stake {
    width: 70px;
    font-size: 13px;
    font-weight: 500;
    color: var(--color-bright-accent);
  }

  .menu {

    &.on {
      position: relative;
      top: -5px;
      width: 100%;
      color: white;
      background: var(--color-reds-dark);   
      height: calc(100vh - 80px - 2rem);
      z-index: 15;
    }

    &.off {
      display: none;
    }
    
    .menu-subtitle {
      display: inline-block;
      font-size: 13px;
      padding: 0.75rem 4rem;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 4px;
      color: #b6b6b6;
    }

    .tag-list {
      padding: 0 4rem;
    }

    .msg {
      margin: 0 4rem;
      padding: 1rem;
      border: 1px solid white;
    }

    .menu-item {
      display: inline-block;
      width: 100%;
      margin: 0.75rem 0;
      padding: 0.75rem 4rem;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.618;
      letter-spacing: 1px;
      color: white;
      font-weight: 300;

      &:first-of-type {
        padding-top: 3em;
      }
      &:hover {
        cursor: pointer;
        background-color: black;
      }
    }

    a, a:link, a:visited {
      color: white;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      text-transform: uppercase;
    }
    .tag {
      display: inline-block;
      margin-right: 0.5rem;
      border-radius: 2px;
      padding: 0.25rem 1rem;
      font-size: 1rem;
      line-height: 1;
      background-color: white;
      color: black;

      &:hover {
        background-color: black;
        color: white;
        cursor: pointer;
      }
    }
  }

  .profile-wrapper {
    &.on {
      position: relative;
      top: 8px;
      width: 100%;
      color: white;
      background: var(--color-pinks-dark);  
      height: calc(100vh - 80px - 2rem);
      z-index: 15;
    }
    &.off {
      display: none;
    }
  }
  
  .wallet-wrapper {
    &.on {
      position: relative;
      top: 8px;
      width: 100%;
      color: white;
      background: var(--color-blues-dark);  
      height: calc(100vh - 80px - 2rem);
      z-index: 15;
    }
    &.off {
      display: none;
    }
  }

}
