@import './assets/css/normalize.css';

@font-face {
  font-family: 'Museo Cyrl 500';
  src: url('./assets/fonts/MuseoCyrl-500.woff2') format('woff2'),
      url('./assets/fonts/MuseoCyrl-500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Light.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Black.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}


:root * {
  --font-fam-main: "Montserrat", sans-serif;
  --font-fam-deco: "Museo Cyrl 500", sans-serif;

  --border-black: 1px solid black;
  --border-white: 1px solid white;
  --border-on-dark: 1px solid #444;
  --border-on-white: 1px solid #ddd;
  
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: var(--font-fam-main);
  font-weight: 300;
  font-size: 16px;
  line-height: 1.618;
  -webkit-font-smoothing: antialiased;
}

body[data-theme="noir"] {
  --color-primary: #088cff;

  --color-white: white;
  --color-black: black;

  --color-next-to-white: #f3f5f7;
  --color-next-to-black: #222222;

  --color-neutral-light: #ccc;
  --color-neutral-medium: #777;
  --color-neutral-dark: #333;

  --color-next-to-neutral-light: #f1efc9;

  --color-most-bright-light: #ffee00;
  --color-most-bright-medium: #04ffff;
  --color-most-bright-dark: #088cff;

  --color-blues-dark: #013788;
  --color-pinks-dark: #7c0fa7;
  --color-reds-dark: #a70f3d;

  --color-light-blue: #6ebbff;



  --color-whitesmoke: #f3f5f7;

  --color-secondary: #370080;

  --color-light:  #ffee00;
  --color-medium: #ff5614;
  --color-bright: #abff0e;
  --color-bright-accent: #ffbe0d;
  --color-blue-300: #6ebbff;
  --color-blue-400: #050505;

  --color-background: #000;

  background-color: black;
}


body[data-theme="grass"] {
  --color-primary: #0583f2;
  
  --color-light:  #f5f7f9;
  --color-medium: rgb(72, 103, 134);
  --color-dark: rgba(34,78,195,1);
  --color-dark-accent: rgba(9, 21, 53,1);
  --color-bright: #0583f2;
  --color-bright-accent: rgb(131, 238, 0);
  background-color: black;
}


body[data-theme='pink'] {
  --color-light:  #f8bbd0;
  --color-medium: #ba8c9c;
  --color-dark: #5b454c;
  --color-bright: #ec407a;
  --color-bright-accent: deeppink;

  background-color: black;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 .5rem 0;
}

.hidden, .hide {
  display: none;
}

