.Account {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    
  .profile-icon {
    display: block;
    width: 128px;

    svg {
      height: 128px;
      width: 128px;
    }
  }

  .user-id {
    display: block; 
    margin: 1rem 0;
    color: #ccc;  
    font-size: 24px;
    font-weight: 400;  
  }

  .input-wrapper {
    display: flex;
    width: 124px;

    input {
      display: inline-block;
      border: 0;
      background-color: transparent;
      color: rgba(255,255,255, 1);
      border-bottom: 1px solid #eee;
      width: 100px;
      font-size: 15px;
      font-weight: 300;
    }

    input[disabled] {
      color: rgba(255,255,255, 0.75);
    }
    
    .inline-icon {
      display: inline-block;
      width: 24px;
      height: auto;

      svg {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }


  .button--primary {
    margin:  2rem auto;
    padding:  0.75rem 2rem;
    position: relative;
    font-size: 15px;
    display: flex;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    color: black;
    text-transform: uppercase;
    visibility: hidden;

    &:hover {
      background-color:  black;
      color: white;
    }
  }

  .button--secondary {
    display: flex;
    justify-content: center;
    margin:  0;
    padding: 0.25rem 0.5rem;
    border-radius: 0;
    font-size: 12px;
    border: none;
    color: white;
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color:  black;
    }
  }

  .hide {
    visibility: visible;
  }

}

