.UserLogin {
  display: flex;
  width: 100%;
  height: 100vh;
  color: black;
  background-color: var(--color-whitesmoke);

  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    .notifications {
      position: absolute;
      top: 0;
      height: 3rem;
      width: 100%;

      @media (min-width: 923px) {
        position: relative;
        top: 0;
        flex: 0 0 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20vh;
      }

      .wrapper {
        position: relative;
        display: flex;
        flex: 3rem;
        height: 3rem;
        align-self: flex-end;
        padding: 0.75rem;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.25;
        justify-content: center;

        @media (min-width: 923px) {
          font-size: 1rem;
        }
      }
    }

    .login-area {
      display: flex;
      width: 100%;
      height: 100vh;

      @media (min-width: 923px) {
        flex: 0 0 60vh;
        height: 60vh;
      }
  
      .login__secondary {
        display: none;

        @media (min-width: 923px) {
          background: var(--color-background);
          background-size: 600% 600%;
          animation: gradient-moving 12s ease infinite;
          display: flex;
          width: 60%;
          height: 60vh;
          flex: 1 1 auto;
          flex-direction: column;
          justify-content: center;
          color: white;
        }

        h1.logo {
          font-size: 25vw;
          font-weight: 500;
          font-family: var(--font-fam-deco);
          padding-left: 5rem;
          line-height: 0.8;
        }
        h2.tagline {
          font-size: 2rem;
          font-weight: 500;
          padding-left: 7rem;
        }
      }
    

      .login__primary {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        background-color: white;
        
        @media (min-width: 923px) {
          width: 30%;
          height: 60vh;
          padding: 2rem 3%;

          & > h1,
          & > form {
            position: relative;
            top: 6vh;
          }
        }
        
        .bg {
          display: flex;
          width: 100%;
          align-items: center;
          height: 50vh;
          background-color: var(--color-background);

          @media (min-width: 923px) {
            display: none;
          }

          h1 {
            font-family: var(--font-fam-deco);
            font-size: 50vw;
            line-height: 0.8;
            color: var(--color-primary);
            padding: 2.5rem 0 0 2rem;
          }

        }
        

        .form {
          justify-self: center;
          display: flex;
          flex-direction: column;
          height: 50vh;
          padding: 0 2.5rem;

          @media (min-width: 923px) {
            justify-content: center;
            height: 60vh;
          }


          h3 {
            font-size: 48px;
            font-weight: 900;
          }
          .hint {
            font-size: 14px;
            line-height: 1.618;
            margin: 0;
            font-weight: 400;
          }

          .input-email {
            display: inline-block;
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            padding:  1rem 1rem 0.25rem;
            margin: 0.5rem 0 2rem;
            outline-width: 1px;

            &::placeholder {
              font-size: 0.875rem;
            }
          }

          .wrapper {
            display: flex;

            .login__button {
              padding: 1.25em 2rem;
              margin: 1.5rem 0;
              font-size: 15px;
              font-weight: 500;
              cursor: pointer;
              color: black;
              background-color: var(--color-most-bright-medium);
              border: 0;
              border-radius: 2px;

              &:hover {
                background-color: var(--color-most-bright-light);
              }
            }
          }
        }
      }
    }
  }
}

// Effects

@keyframes gradient-moving {
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}




/* Clip text element */
.clip-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Background */
.clip-text:before {
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: inherit;
}

.clip-text_one {
  background-image: linear-gradient(309deg, #11066b, #0859ff, #c1d4e7, #85ceff, #0859ff, #b6c9f3, rgb(206, 203, 223), #33acff, #0859ff, rgb(196, 205, 209), #8099eb, #11066b);
  background-size: 600% 600%;
  animation: gradient-moving 12s ease infinite;
}

.clip-text_two {
  background-image: linear-gradient(309deg, #ff2828, #ffda08, #04ffff, #ff5100, #ff08de, #33acff, #d2199a, #b1ff33, #ff4e08, #ffae00, #80ebe2, #ff2828);
  background-size: 600% 600%;
  animation: gradient-moving 12s ease infinite;
}
